import React, { useEffect, useRef, useState } from 'react';
import bread from "./assets/bread.png"
import chef from "./assets/chef.png"
import cookie from "./assets/cookie.png"
import "./assets/main.css"

const App = () => {
  const [link, setLink] = useState('');
  const [textArea, spawnTextArea] = useState(false);
  const [steps, setSteps] = useState('');
  const [ingredients, setIngredients] = useState('');
  const textDivRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
   
    try {
      console.log(link)
      if(link==="" || !link?.includes("https")){
        setError("Come on, you know how to copy and paste a URL properly")
      }else{
        setError("")
        setIsLoading(true); // Show spinner

        var url = 'https://staging.picktheory.com/api/v1/chat-gpt/getRecipeFromURL'
        if (window.location.hostname === 'localhost') {
            url='http://localhost:8080/api/v1/chat-gpt/getRecipeFromURL';
        }
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ URL: link }), // Send the URL in the request body
        });

        // if (!response.ok) {
        //   throw new Error(`HTTP error! Status: ${response.status}`);
        // }

        const data = await response.json(); // Assuming the server responds with JSON
        console.log('Received data:', data); // Handle the response data
        if(data.success === true){
          const message = JSON.parse(data.message)
          setIngredients(message.ingredients);
          setSteps(message.instructions);
          
          spawnTextArea(true)
        }else{
          setError("Please try again, this recipe is not readable")
        }
        setIsLoading(false); // Show spinner
        console.log('Received data:', data); // Handle the response data
      }
    } catch (error) {
      console.log('Failed to fetch:', error); // Handle any errors
    }
  };

  useEffect(() => {
    // Automatically scroll to the div when it mounts
    if(textArea){
      textDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [textArea]);

  return (
    <div style={{
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'start', 
      height: '100%',
      marginTop:'15%',
      overflowX:'hidden',
      overflowY:'scroll'
    }}>
      <h1 style={{fontFamily:'Arial'}}>Tasty Links</h1>
      
      <img src={chef} alt="Logo" style={{ marginBottom: '20px', width: '100px', height: '100px' }} />
      
      {isLoading ? (
        <div className='load-container'>
          <div style={{fontFamily:'Arial',textAlign:'center',marginBottom:'4px'}}>Yes, Chef!</div>
            <div className="loading-bar-container">
            <div className="loading-bar"></div>
          </div>
        </div>
      ) : (
      <form onSubmit={handleSubmit} style={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        width: '100%',
        maxWidth:'600px',
        padding: '5%',
      }}>
        
        {error != '' && 
        <h4 style={{color:'red', fontFamily:'Arial', textAlign:'center', paddingLeft:'10px', paddingRight:'10px'}}>{error}</h4>}

        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="Enter URL here..."
          style={{
            padding: '10px',
            width: '100%',
            height: '60px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            boxSizing: 'border-box', // Ensures padding doesn't affect the width
          }}
        />
        <button type="submit" style={{
          width:'100%',
          maxWidth:'600px',
          height: '60px',
          marginTop:'20px',
          borderRadius: '5px',
          border: 'none',
          backgroundColor: '#007BFF',
          color: 'white',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}>Generate Recipe</button>
      </form>)}

      {textArea && 
      <div className="text-div" ref={textDivRef}>
        <h2>Ingredients</h2>
        <ul className="custom-list">
          {ingredients.map((ingredient, index) => (
            <li key={index} className='ingredients'>{ingredient}</li>
          ))}
        </ul>
      
        <h2>Steps</h2>
        <ul className="custom-list">
          {steps.map((step, index) => (
            <li key={index} className='steps'>{step}</li>
          ))}
        </ul>
      </div>
      }
    </div>
  );
};

export default App;
